import { CHECKBOX_FIELD_TESTID } from "@/tests/data/selectors/app/public-selectors";
import clsx from "clsx";
import React from "react";

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  isControllableByOuterComponent?: boolean;
}

/**
 * Компонент для отображения чекбокса.
 *
 * @param {Props} props - Свойства компонента.
 * @param {boolean} props.isControllableByOuterComponent - Флаг, указывающий, управляется ли чекбокс внешним компонентом.
 * @param {React.ReactNode} props.children - Дочерние элементы чекбокса.
 * @param {boolean} props.readOnly - Флаг, указывающий, является ли чекбокс только для чтения.
 * @param {boolean} props.checked - Флаг, указывающий, отмечен ли чекбокс.
 * @param {string} props.className - Классы для чекбокса.
 * @param {boolean} props.disabled - Флаг, указывающий, отключен ли чекбокс.
 * @param {string} props.value - Значение чекбокса.
 *
 */

// TODO: нет состояния для ховера при чекнутом чекбоксе и нет состояния для фокуса по клавиатуре
const Checkbox: React.FC<Props> = React.forwardRef<HTMLInputElement, Props>(
  ({ children, readOnly, checked, className, disabled, value, isControllableByOuterComponent, ...props }, ref) => {
    return (
      <label
        className={clsx(
          "group flex items-center",
          disabled || readOnly ? "cursor-not-allowed" : "hover:cursor-pointer",
          className,
        )}
      >
        <input
          ref={ref}
          type="checkbox"
          className="peer absolute appearance-none"
          disabled={isControllableByOuterComponent ? true : disabled}
          checked={checked}
          readOnly={readOnly}
          {...props}
        />
        <span
          data-testid={CHECKBOX_FIELD_TESTID}
          className={clsx(
            `
            flex h-6 w-6 flex-shrink-0 items-center justify-center
            rounded-lg
            border border-line bg-center

            bg-no-repeat
            text-white
        `,
            readOnly && checked && "border-none bg-bg",
            !readOnly && checked && "border-none bg-seaBlue",
            readOnly && !checked && "bg-bg",
            !readOnly && !checked && "bg-white group-hover:border-primary group-hover:bg-[#ECF1F5]",
            isControllableByOuterComponent
              ? ""
              : "peer-disabled:cursor-not-allowed peer-disabled:border-line peer-disabled:opacity-30",
          )}
        >
          <svg
            width="15"
            height="12"
            viewBox="0 0 15 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={clsx({
              block: checked,
              hidden: !checked,
              "stroke-white": !readOnly,
              "stroke-disabled": readOnly,
            })}
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M13.9426 1.14785C14.1716 1.35777 14.1871 1.71359 13.9772 1.9426L5.72715 10.9426C5.62348 11.0557 5.47811 11.1215 5.32472 11.1249C5.17133 11.1282 5.02324 11.0687 4.91475 10.9603L1.16475 7.21025C0.945083 6.99058 0.945083 6.63443 1.16475 6.41476C1.38442 6.19509 1.74058 6.19509 1.96025 6.41476L5.29483 9.74934L13.1479 1.18241C13.3578 0.953404 13.7136 0.937933 13.9426 1.14785Z"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </span>
        <span
          className={clsx("ml-3", disabled && "opacity-60", {
            "text-label": readOnly,
            "text-primaryText": !readOnly,
          })}
        >
          {children}
        </span>
      </label>
    );
  },
);

export default Checkbox;

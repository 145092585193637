import Modal, { ModalResult } from "@/components/Modal";
import { AppError } from "@/domain";
import { parseErrorMessage } from "@/shared";

interface ErrorModalProps {
  error: AppError;
  onClose: () => void;
}

export const ErrorModal = ({ error, onClose }: ErrorModalProps) => {
  const parsedError = parseErrorMessage(error.errorMessage ?? "");

  return (
    // TODO: change modal to modalComponent
    <Modal open={true} onClose={onClose} className="w-full at768:w-[420px]">
      <ModalResult
        success={false}
        title={parsedError.title}
        subtitle={
          parsedError.description ? (
            <span dangerouslySetInnerHTML={{ __html: parsedError.description }}></span>
          ) : (
            "Произошла ошибка, попробуйте повторить"
          )
        }
        errorCode={error?.errorCode}
        buttonText="Назад"
        onButtonClick={onClose}
      />
    </Modal>
  );
};

import { Dialog } from "@headlessui/react";
import clsx from "clsx";
import React from "react";

interface Props {
  open: boolean;
  onClose: () => void;
  className?: string;
}

const Modal: React.FC<Props> = ({ open, onClose, className, children }) => {
  return (
    // TODO: change modal to modalComponent
    <Dialog
      as="div"
      className={clsx(
        "fixed inset-0 z-[90000] overflow-y-auto",
        // "flex items-center justify-center"
      )}
      open={open}
      onClose={onClose}
    >
      <Dialog.Overlay className="fixed inset-0 bg-black opacity-70" />
      <div
        className={clsx(
          "left-1/2 top-1/2 inline-block -translate-x-1/2 -translate-y-1/2",
          "absolute z-10 overflow-y-auto rounded-3xl bg-white p-6 text-title shadow-[0_20px_40px_rgba(44,152,240,0.2)]",
          className,
        )}
      >
        {children}
      </div>
    </Dialog>
  );
};

export default Modal;

import {
  ActualOrderEntity,
  CalculateOrderTariffPublicRequest,
  CalculateTariffRequest,
  CancelOrderRequest,
  CreateOrderRequest,
  DeliveryType,
  GetOrderByIdResponse,
  LocationType,
  MapResponse,
  MarkerTypePoint,
  OrderEntity,
  OrderListEntity,
  OrderStatusEntry,
  OrderTariffEntity,
  OrderTariffType,
  ResultResponse,
  RoutePointTuple,
  SearchOrdersRequest,
  SearchOrdersResponse,
  StatusStepState,
  UpdateOrderTariffsRequest,
} from "@/domain";
import {
  AccountCheckPhoneController_ResultBoolResponse,
  AgOrderEntity,
  AgOrderStatusEntity,
  CancelOrderController_AgOrderCancelRequest,
  GetActualOrdersListController_AgOrderListRequest,
  GetActualOrdersListController_AgOrderListResponse,
  GetArchiveOrdersListController_AgOrderListRequest,
  GetOrderController_AgSingleOrderResponse,
  GetOrderRouteMapController_AgGetOrderRouteMapResponse,
  GetOrderStatusChainController_AgOrderCustomerStatusChainResponse,
  GetPublicTariffEmptyContainerController_PublicTariffCalculationEmptyContainerRequest,
  GetPublicTariffEmptyContainerController_PublicTariffCalculationResponse,
  GetPublicTariffFirstMileController_PublicTariffCalculationFirstMileRequest,
  GetPublicTariffFirstMileController_PublicTariffCalculationResponse,
  GetPublicTariffLastMileController_PublicTariffCalculationLastMileRequest,
  GetPublicTariffLastMileController_PublicTariffCalculationResponse,
  GetTariffEmptyContainerController_TariffCalculationEmptyContainerRequest,
  GetTariffEmptyContainerController_TariffCalculationResponse,
  GetTariffFirstMileController_TariffCalculationFirstMileRequest,
  GetTariffFirstMileController_TariffCalculationResponse,
  GetTariffLastMileController_TariffCalculationLastMileRequest,
  GetTariffLastMileController_TariffCalculationResponse,
  LastActualOrdersEntity,
  type LatLonEntity,
  type MarkerEntity,
  OrderListEntity as KuberOrderListEntity,
  PlaceOrderEmptyContainerController_OrderEmptyContainerRequest,
  PlaceOrderFirstMileController_OrderFirstMileRequest,
  PlaceOrderLastMileController_OrderLastMileRequest,
  TariffCalculationEntity,
  UpdateOrderTariffController_AgUpdateOrderTariffRequest,
} from "../codegen";
import { toKuberEnum } from "../shared";
import { OrderStatusType } from "@/data/constants";
import { fromComboInputValueToDateTimeFormat } from "@/dashboard/create-order/utils";
import { tariffPositionLabels, TariffPositionName } from "@/dashboard/create-order/constants";
import { MarkerName } from "@/admin/data/constants";

export function toActualOrderEntity(entity: LastActualOrdersEntity): ActualOrderEntity {
  return {
    deliveryType: entity.delivery_type?.value as DeliveryType,
    pickupAdddress: entity.pickup_address!,
    deliveryAddress: entity.delivery_address!,
    warehouseAddress: entity.warehouse_address || undefined,
    moverTariff: entity.mover_tariff!,
  };
}

export function fromCalculateTariffResponse(
  response:
    | GetPublicTariffEmptyContainerController_PublicTariffCalculationResponse
    | GetPublicTariffFirstMileController_PublicTariffCalculationResponse
    | GetPublicTariffLastMileController_PublicTariffCalculationResponse,
) {
  // TODO: codegen
  // @ts-ignore
  if (response.customer_full_tariff) {
    // @ts-ignore
    return response.customer_full_tariff as number;
  }

  return response.tariff?.customer_full_tariff || 0;
}

export function toPublicCalculateTariffRequest(
  request: CalculateOrderTariffPublicRequest,
): GetPublicTariffEmptyContainerController_PublicTariffCalculationEmptyContainerRequest {
  return {
    pickup_city: toKuberEnum(request.pickupCity),
    pickup_terminal: toKuberEnum(request.pickupTerminal),
    delivery_city: toKuberEnum(request.deliveryCity),
    delivery_terminal: toKuberEnum(request.deliveryTerminal),
    how_to_carry: request.howToCarry ? toKuberEnum(request.howToCarry) : undefined,
    container_type: toKuberEnum(request.containerType),
    cargos: request.cargos.map((c) => ({
      container_height: c.containerHeight ? toKuberEnum(c.containerHeight) : undefined,
      cargo_weight: c.cargoWeight,
    })),
  };
}

export function toPublicFirstMileTariffRequest(
  request: CalculateOrderTariffPublicRequest,
): GetPublicTariffFirstMileController_PublicTariffCalculationFirstMileRequest {
  return {
    pickup_city: toKuberEnum(request.pickupCity),
    pickup_terminal: toKuberEnum(request.pickupTerminal),
    delivery_city: toKuberEnum(request.deliveryCity),
    delivery_terminal: toKuberEnum(request.deliveryTerminal),
    how_to_carry: request.howToCarry ? toKuberEnum(request.howToCarry) : undefined,
    container_type: toKuberEnum(request.containerType),
    cargos: request.cargos.map((c) => ({
      container_height: c.containerHeight ? toKuberEnum(c.containerHeight) : undefined,
      cargo_weight: c.cargoWeight,
    })),
    warehouse_address: request.warehouseAddress!,
  };
}

export function toPublicLastMileTariffRequest(
  request: CalculateOrderTariffPublicRequest,
): GetPublicTariffLastMileController_PublicTariffCalculationLastMileRequest {
  return {
    pickup_city: toKuberEnum(request.pickupCity),
    pickup_terminal: toKuberEnum(request.pickupTerminal),
    delivery_city: toKuberEnum(request.deliveryCity),
    delivery_terminal: toKuberEnum(request.deliveryTerminal),
    how_to_carry: request.howToCarry ? toKuberEnum(request.howToCarry) : undefined,
    container_type: toKuberEnum(request.containerType),
    cargos: request.cargos.map((c) => ({
      container_height: c.containerHeight ? toKuberEnum(c.containerHeight) : undefined,
      cargo_weight: c.cargoWeight,
    })),
    warehouse_address: request.warehouseAddress!,
  };
}

export function fromFirstMileTariffRequest(
  request: CalculateTariffRequest,
): GetTariffFirstMileController_TariffCalculationFirstMileRequest {
  if (!request.warehouseAddress) throw new Error("warehouseAddress is required field");
  if (!request.warehouseDeliveryDate) throw new Error("warehouseDeliveryDate is required field");

  const isCargoPropertiesSpecified =
    request.cargoProperties &&
    (request.cargoProperties?.dangerClass ||
      !!request.cargoProperties?.temperatureFrom ||
      !!request.cargoProperties?.temperatureTo);

  return {
    pickup_city: toKuberEnum(request.pickupCity),
    pickup_terminal: toKuberEnum(request.pickupTerminal),
    delivery_city: toKuberEnum(request.deliveryCity),
    delivery_terminal: toKuberEnum(request.deliveryTerminal),
    how_to_carry: request.howToCarry ? toKuberEnum(request.howToCarry) : undefined,
    container_type: toKuberEnum(request.containerType),
    cargos: request.cargos.map((c) => ({
      container_height: c.containerHeight ? toKuberEnum(c.containerHeight) : undefined,
      cargo_weight: c.cargoWeight,
      cargo_cost: c.cargoCost,
      cargo_name: c.cargoName,
      container_number: c.containerNumber,
    })),
    cargo_properties: isCargoPropertiesSpecified
      ? {
          danger_class: request.cargoProperties?.dangerClass,
          temperature_from: request.cargoProperties?.temperatureFrom,
          temperature_to: request.cargoProperties?.temperatureTo,
        }
      : undefined,
    payment_type: toKuberEnum(request.paymentType),
    warehouse_address: request.warehouseAddress,
    options: request.options.map((o) => toKuberEnum(o)),
    warehouse_date_time_period: fromComboInputValueToDateTimeFormat(request.warehouseDeliveryDate),
    promo_code: request.promo,
  };
}

export function fromLastMileTariffRequest(
  request: CalculateTariffRequest,
): GetTariffLastMileController_TariffCalculationLastMileRequest {
  if (!request.warehouseAddress) throw new Error("warehouseAddress is required field");
  if (!request.warehouseDeliveryDate) throw new Error("warehouseDeliveryDate is required field");

  const isCargoPropertiesSpecified =
    request.cargoProperties &&
    (request.cargoProperties?.dangerClass ||
      !!request.cargoProperties?.temperatureFrom ||
      !!request.cargoProperties?.temperatureTo);

  return {
    pickup_city: toKuberEnum(request.pickupCity),
    pickup_terminal: toKuberEnum(request.pickupTerminal),
    delivery_city: toKuberEnum(request.deliveryCity),
    delivery_terminal: toKuberEnum(request.deliveryTerminal),
    how_to_carry: request.howToCarry ? toKuberEnum(request.howToCarry) : undefined,
    container_type: toKuberEnum(request.containerType),
    cargos: request.cargos.map((c) => ({
      container_height: c.containerHeight ? toKuberEnum(c.containerHeight) : undefined,
      cargo_weight: c.cargoWeight,
      cargo_cost: c.cargoCost,
      cargo_name: c.cargoName,
      container_number: c.containerNumber,
    })),
    cargo_properties: isCargoPropertiesSpecified
      ? {
          danger_class: request.cargoProperties?.dangerClass,
          temperature_from: request.cargoProperties?.temperatureFrom,
          temperature_to: request.cargoProperties?.temperatureTo,
        }
      : undefined,
    payment_type: toKuberEnum(request.paymentType),
    warehouse_address: request.warehouseAddress,
    options: request.options.map((o) => toKuberEnum(o)),
    warehouse_date_time_period: fromComboInputValueToDateTimeFormat(request.warehouseDeliveryDate),
    promo_code: request.promo,
  };
}

export function fromEmptyContainerTariffRequest(
  request: CalculateTariffRequest,
): GetTariffEmptyContainerController_TariffCalculationEmptyContainerRequest {
  if (!request.warehouseDeliveryDate) throw new Error("warehouseDeliveryDate is required field");
  return {
    pickup_city: toKuberEnum(request.pickupCity),
    pickup_terminal: toKuberEnum(request.pickupTerminal),
    delivery_city: toKuberEnum(request.deliveryCity),
    delivery_terminal: toKuberEnum(request.deliveryTerminal),
    how_to_carry: request.howToCarry ? toKuberEnum(request.howToCarry) : undefined,
    container_type: toKuberEnum(request.containerType),
    cargos: request.cargos.map((c) => ({
      container_height: c.containerHeight ? toKuberEnum(c.containerHeight) : undefined,
      cargo_weight: c.cargoWeight,
      cargo_cost: c.cargoCost,
      cargo_name: c.cargoName,
      container_number: c.containerNumber,
    })),
    payment_type: toKuberEnum(request.paymentType),
    delivery_date_time_period: fromComboInputValueToDateTimeFormat(request.warehouseDeliveryDate),
    options: request.options.map((o) => toKuberEnum(o)),
    promo_code: request.promo,
  };
}

export function toOrderTariffs(
  response:
    | GetTariffFirstMileController_TariffCalculationResponse
    | GetTariffLastMileController_TariffCalculationResponse
    | GetTariffEmptyContainerController_TariffCalculationResponse,
): {
  tariffs?: OrderTariffEntity[];
  distance: number;
  markers: MarkerTypePoint[];
  routeMap: RoutePointTuple[][];
} {
  if (!response.tariffs)
    return {
      tariffs: [],
      distance: 0,
      markers: [],
      routeMap: [],
    };

  return {
    tariffs: response.tariffs.map(toOrderTariffEntity),
    distance: response.distance ?? 0,
    markers: (response.route_map_entity?.marker_entities ? response.route_map_entity.marker_entities : []).map(
      (point) => fromMarkerObjectToMarkerType(point),
    ),
    routeMap: (response.route_map_entity?.haul_distance ? response.route_map_entity.haul_distance : []).map((route) =>
      prepareRoute(route.projected_point),
    ),
  };
}

export function fromCancelOrderRequest(request: CancelOrderRequest): CancelOrderController_AgOrderCancelRequest {
  return {
    order_id_code: request.orderId,
    comment: request.comment,
  };
}

export function toOrderEntity(entity: AgOrderEntity): OrderEntity {
  return {
    id: entity.order_id_code!,
    createdAt: entity.created_at!,
    deliveryType: entity.delivery_type?.value! as DeliveryType,
    cargos: entity.cargos
      ? entity.cargos.map((c) => ({
          containerNumber: c.container_number ?? undefined,
          containerHeight: c.container_height ? c.container_height.value : undefined,
          cargoName: c.cargo_name ?? undefined,
          cargoCost: c.cargo_cost ?? undefined,
          cargoWeight: c.cargo_weight ?? undefined,
        }))
      : [],
    cargoProperties: {
      dangerClass: entity.order_cargo_properties?.danger_class ?? undefined,
      temperatureFrom: entity.order_cargo_properties?.temperature_from ?? undefined,
      temperatureTo: entity.order_cargo_properties?.temperature_to ?? undefined,
    },
    pickupCity: entity.pickup_city?.value! as LocationType,
    pickupTerminal: entity.pickup_terminal?.value!,
    warehouseAddress: entity.warehouse_address?.address,
    warehouseDateTimePeriod: entity.warehouse_date_time_period ?? undefined,
    deliveryCity: entity.delivery_city?.value! as LocationType,
    deliveryTerminal: entity.delivery_terminal?.value!,
    contacts: entity.contacts_warehouse
      ? entity.contacts_warehouse.map((c) => ({ name: c.name!, phone: c.phone! }))
      : [],
    paymentType: entity.payment_type?.value!,
    options: entity.options ? entity.options.map((o) => o.value!) : [],
    additionalInfo: entity.additional_info ?? undefined,
    containerType: entity.container_type?.value!,
    howToCarry: entity.how_to_carry?.value,
    warehouseCompany: entity.warehouse_company!,
    shipperCompany: entity.shipper_company!,
    consigneeCompany: entity.consignee_company!,
    clientCompany: entity.client_company ?? undefined,
    currentCustomerStatus: entity.current_customer_status.value as OrderStatusType,
    instructionEntity: entity.instruction_entity,
  };
}

export function toOrderTariffEntity(entity: TariffCalculationEntity): OrderTariffEntity {
  return {
    customerFullTariff: entity.customer_full_tariff ?? 0,
    customerFullTariffNds: entity.nds_customer_full_tariff ?? 0,
    baseTariffNds: entity.nds_base_tariff ?? 0,
    oldFullTariff: entity.old_full_tariff ?? 0,
    baseTariff: entity.base_tariff ?? 0,
    options: entity.dir_option_tariff
      ? entity.dir_option_tariff.map((o) => ({ name: o.name!, sum: o.value!, nds: o.nds_option ?? undefined }))
      : [],
    additionals: entity.dir_additional_coefficients
      ? entity.dir_additional_coefficients.map((o) => ({
          name: tariffPositionLabels[o.id?.value as TariffPositionName],
          sum: o.sum!,
          nds: o.nds ?? undefined,
        }))
      : [],
    discounts: entity.discount_tariff
      ? entity.discount_tariff.map((o) => ({
          name: tariffPositionLabels[o.id?.value as TariffPositionName],
          sum: o.sum_discount ?? 0,
          nds: o.nds_sum_discount ?? undefined,
        }))
      : [],
    type: entity.dir_nds_type_enum?.value! as OrderTariffType,
    isActive: entity.active ?? false,
    isSelected: entity.selected ?? false,
  };
}

export function fromOrderTariffEntity(entity: OrderTariffEntity): TariffCalculationEntity {
  return {
    customer_full_tariff: entity.customerFullTariff,
    nds_customer_full_tariff: entity.customerFullTariffNds,
    nds_base_tariff: entity.baseTariffNds,
    base_tariff: entity.baseTariff,
    dir_option_tariff: entity.options.length > 0 ? entity.options : undefined,
    dir_additional_coefficients: entity.additionals.length > 0 ? entity.additionals : undefined,
    dir_nds_type_enum: toKuberEnum(entity.type),
    active: entity.isActive,
    selected: entity.isSelected,
  };
}

export function toGetOrderByIdResponse(response: GetOrderController_AgSingleOrderResponse): GetOrderByIdResponse {
  return {
    order: toOrderEntity(response.order!),
    tariffs: response.order_tariff.map((t) => toOrderTariffEntity(t)),
    noFormatTariffs: response.order_tariff,
    noFormatOrder: response.order,
    documents: response.order_file_documents
      ? response.order_file_documents.map((d) => ({
          typeId: d.document_type?.value!,
          id: d.attachment_name!,
          originalName: d.original_name!,
          mimeType: d.mime_type?.value!,
          extension: d.extension?.value!,
        }))
      : [],
    documentPackages: response.document_packages
      ? response.document_packages.map((d) => ({
          createdAt: d.created_at ?? "",
          documentPackageId: d.document_package_id_code ?? "",
        }))
      : [],
    statusChain: response.customer_status_chain
      ? response.customer_status_chain?.map((e) => toOrderStatusEntry(e))
      : [],

    transportConfirmation: response.transport_confirmation
      ? {
          text: response.transport_confirmation.text!,
          textConfirmed: response.transport_confirmation.text_confirmed!,
          isButtonActive: response.transport_confirmation.button_active ?? false,
        }
      : undefined,
    // TODO: codegen
    mover: response.order_mover
      ? {
          truck: response.order_mover.truck_gov_number
            ? {
                model: response.order_mover.truck_model!,
                brand: response.order_mover.truck_brand!,
                govNumber: response.order_mover.truck_gov_number!,
              }
            : undefined,
          semiTrailer: response.order_mover.semi_trailer_gov_number
            ? {
                model: response.order_mover.semi_trailer_model!,
                brand: response.order_mover.semi_trailer_brand!,
                govNumber: response.order_mover.semi_trailer_gov_number!,
              }
            : undefined,
          driver: response.order_mover.driver_first_name
            ? {
                firstName: response.order_mover.driver_first_name!,
                lastName: response.order_mover.driver_last_name!,
                middleName: response.order_mover.driver_middle_name!,
                phone: response.order_mover.driver_phone!,
                registrationAddress: response.order_mover.driver_registration_address!,
              }
            : undefined,
          driverPassport: response.order_mover.driver_passport_number
            ? {
                serial: response.order_mover.driver_passport_series!,
                number: response.order_mover.driver_passport_number!,
                issuedBy: response.order_mover.driver_passport_issued_by!,
                issueDate: response.order_mover.driver_passport_issue_date!,
                departmentCode: response.order_mover.driver_passport_department_code!,
              }
            : undefined,
          driverLicense: response.order_mover.driver_license_number
            ? {
                serial: response.order_mover.driver_license_series,
                number: response.order_mover.driver_license_number,
                expireDate: response.order_mover.driver_license_expiry_date,
                issueDate: response.order_mover.driver_license_issue_date,
              }
            : undefined,
        }
      : undefined,
  };
}

export function fromCreateFirstMileOrderRequest(
  request: CreateOrderRequest,
): PlaceOrderFirstMileController_OrderFirstMileRequest {
  if (!request.warehouseAddress) throw new Error("warehouseAddress is required field");
  if (!request.warehouseDeliveryDate) throw new Error("warehouseDeliveryDate is required field");
  // if (!request.warehouseDeliveryTimePeriod) throw new Error("warehouseDeliveryTimePeriod is required field");
  if (!request.warehouseCompanyInn) throw new Error("warehouseCompanyInn is required field");

  const isInstructionEntityAvailable =
    !!request.pickupRelease ||
    !!request.deliveryRelease ||
    !!request.pickupStock ||
    !!request.deliveryStock ||
    !!request.pickupInstruction ||
    !!request.deliveryInstruction;

  const isCargoPropertiesSpecified =
    request.cargoProperties &&
    (request.cargoProperties?.dangerClass ||
      !!request.cargoProperties?.temperatureFrom ||
      !!request.cargoProperties?.temperatureTo);

  return {
    cargos: request.cargos.map((c) => ({
      container_height: c.containerHeight ? toKuberEnum(c.containerHeight) : undefined,
      cargo_weight: c.cargoWeight,
      cargo_cost: c.cargoCost,
      cargo_name: c.cargoName,
      container_number: c.containerNumber,
      zpu: c.zpu,
    })),
    cargo_properties: isCargoPropertiesSpecified
      ? {
          danger_class: request.cargoProperties?.dangerClass,
          temperature_from: request.cargoProperties?.temperatureFrom,
          temperature_to: request.cargoProperties?.temperatureTo,
        }
      : undefined,
    container_type: toKuberEnum(request.containerType),
    how_to_carry: request.howToCarry ? toKuberEnum(request.howToCarry) : undefined,
    pickup_city: toKuberEnum(request.pickupCity),
    pickup_terminal: toKuberEnum(request.pickupTerminal),
    delivery_city: toKuberEnum(request.deliveryCity),
    delivery_terminal: toKuberEnum(request.deliveryTerminal),
    payment_type: toKuberEnum(request.paymentType),
    warehouse_address: request.warehouseAddress,
    warehouse_company_inn: request.warehouseCompanyInn,
    contacts_warehouse: request.warehouseContacts,
    additional_info: request.additionalInfo,
    input_scans: request.attachments,
    shipper_inn: request.shipperInn,
    consignee_inn: request.consigneeInn,
    client_inn: request.clientInn,
    tariff: request.tariffs.map(fromOrderTariffEntity),
    warehouse_date_time_period: fromComboInputValueToDateTimeFormat(request.warehouseDeliveryDate),
    promo_code: request.promo,
    instruction_entity: isInstructionEntityAvailable
      ? {
          pickup_release: !!request.pickupRelease ? request.pickupRelease : undefined,
          delivery_release: !!request.deliveryRelease ? request.deliveryRelease : undefined,
          pickup_stock: !!request.pickupStock ? request.pickupStock : undefined,
          delivery_stock: !!request.deliveryStock ? request.deliveryStock : undefined,
          pickup_instruction: !!request.pickupInstruction ? request.pickupInstruction : undefined,
          delivery_instruction: !!request.deliveryInstruction ? request.deliveryInstruction : undefined,
        }
      : null,
  };
}

export function fromCreateLastMileOrderRequest(
  request: CreateOrderRequest,
): PlaceOrderLastMileController_OrderLastMileRequest {
  if (!request.warehouseAddress) throw new Error("warehouseAddress is required field");
  if (!request.warehouseDeliveryDate) throw new Error("warehouseDeliveryDate is required field");
  // if (!request.warehouseDeliveryTimePeriod) throw new Error("warehouseDeliveryTimePeriod is required field");

  const isInstructionEntityAvailable =
    !!request.pickupRelease ||
    !!request.deliveryRelease ||
    !!request.pickupStock ||
    !!request.deliveryStock ||
    !!request.pickupInstruction ||
    !!request.deliveryInstruction;

  const isCargoPropertiesSpecified =
    request.cargoProperties &&
    (request.cargoProperties?.dangerClass ||
      !!request.cargoProperties?.temperatureFrom ||
      !!request.cargoProperties?.temperatureTo);

  return {
    cargos: request.cargos.map((c) => ({
      container_height: c.containerHeight ? toKuberEnum(c.containerHeight) : undefined,
      cargo_weight: c.cargoWeight,
      cargo_cost: c.cargoCost,
      cargo_name: c.cargoName,
      container_number: c.containerNumber,
      zpu: c.zpu,
    })),
    cargo_properties: isCargoPropertiesSpecified
      ? {
          danger_class: request.cargoProperties?.dangerClass,
          temperature_from: request.cargoProperties?.temperatureFrom,
          temperature_to: request.cargoProperties?.temperatureTo,
        }
      : undefined,
    container_type: toKuberEnum(request.containerType),
    how_to_carry: request.howToCarry ? toKuberEnum(request.howToCarry) : undefined,
    pickup_city: toKuberEnum(request.pickupCity),
    pickup_terminal: toKuberEnum(request.pickupTerminal),
    delivery_city: toKuberEnum(request.deliveryCity),
    delivery_terminal: toKuberEnum(request.deliveryTerminal),
    payment_type: toKuberEnum(request.paymentType),
    warehouse_address: request.warehouseAddress,
    contacts_warehouse: request.warehouseContacts,
    additional_info: request.additionalInfo,
    input_scans: request.attachments,
    shipper_inn: request.shipperInn,
    consignee_inn: request.consigneeInn,
    client_inn: request.clientInn,
    tariff: request.tariffs.map(fromOrderTariffEntity),
    warehouse_date_time_period: fromComboInputValueToDateTimeFormat(request.warehouseDeliveryDate),
    promo_code: request.promo,
    instruction_entity: isInstructionEntityAvailable
      ? {
          pickup_release: !!request.pickupRelease ? request.pickupRelease : undefined,
          delivery_release: !!request.deliveryRelease ? request.deliveryRelease : undefined,
          pickup_stock: !!request.pickupStock ? request.pickupStock : undefined,
          delivery_stock: !!request.deliveryStock ? request.deliveryStock : undefined,
          pickup_instruction: !!request.pickupInstruction ? request.pickupInstruction : undefined,
          delivery_instruction: !!request.deliveryInstruction ? request.deliveryInstruction : undefined,
        }
      : null,
  };
}

export function fromCreateEmptyContainerOrderRequest(
  request: CreateOrderRequest,
): PlaceOrderEmptyContainerController_OrderEmptyContainerRequest {
  if (!request.warehouseDeliveryDate) throw new Error("warehouseDeliveryDate is required field");

  const isInstructionEntityAvailable =
    !!request.pickupRelease ||
    !!request.deliveryRelease ||
    !!request.pickupStock ||
    !!request.deliveryStock ||
    !!request.pickupInstruction ||
    !!request.deliveryInstruction;

  return {
    cargos: request.cargos.map((c) => ({
      container_height: c.containerHeight ? toKuberEnum(c.containerHeight) : undefined,
      cargo_weight: c.cargoWeight,
      cargo_cost: c.cargoCost,
      cargo_name: c.cargoName,
      container_number: c.containerNumber,
      zpu: c.zpu,
    })),
    container_type: toKuberEnum(request.containerType),
    how_to_carry: request.howToCarry ? toKuberEnum(request.howToCarry) : undefined,
    pickup_city: toKuberEnum(request.pickupCity),
    pickup_terminal: toKuberEnum(request.pickupTerminal),
    delivery_city: toKuberEnum(request.deliveryCity),
    delivery_terminal: toKuberEnum(request.deliveryTerminal),
    payment_type: toKuberEnum(request.paymentType),
    additional_info: request.additionalInfo,
    shipper_inn: request.shipperInn,
    consignee_inn: request.consigneeInn,
    client_inn: request.clientInn,
    tariff: request.tariffs.map(fromOrderTariffEntity),
    delivery_date_time_period: fromComboInputValueToDateTimeFormat(request.warehouseDeliveryDate),
    promo_code: request.promo,
    instruction_entity: isInstructionEntityAvailable
      ? {
          pickup_release: !!request.pickupRelease ? request.pickupRelease : undefined,
          delivery_release: !!request.deliveryRelease ? request.deliveryRelease : undefined,
          pickup_stock: !!request.pickupStock ? request.pickupStock : undefined,
          delivery_stock: !!request.deliveryStock ? request.deliveryStock : undefined,
          pickup_instruction: !!request.pickupInstruction ? request.pickupInstruction : undefined,
          delivery_instruction: !!request.deliveryInstruction ? request.deliveryInstruction : undefined,
        }
      : null,
  };
}

export function fromUpdateOrderTariffsRequest(
  request: UpdateOrderTariffsRequest,
): UpdateOrderTariffController_AgUpdateOrderTariffRequest {
  return {
    order_id_code: request.orderId,
    tariff_calculation_entities: request.tariffs.map(fromOrderTariffEntity),
  };
}

function toOrderStatusEntry(entity: AgOrderStatusEntity): OrderStatusEntry {
  return {
    status: {
      id: entity.status.value!,
      state: entity.status_step ? (entity.status_step.value as StatusStepState) : undefined,
    },
    comment: entity.comment ?? undefined,
    cancellationReason: entity.cancellation_reason?.value ?? undefined,
    createdAt: entity.created_at ?? undefined,
  };
}

export function toOrderStatusChain(
  request: GetOrderStatusChainController_AgOrderCustomerStatusChainResponse,
): OrderStatusEntry[] {
  // TODO: codegen
  return request.order_customer_status_chain
    ? request.order_customer_status_chain.map((entry) => toOrderStatusEntry(entry))
    : [];
}

export function toOrderListEntity(entity: KuberOrderListEntity): OrderListEntity {
  // TODO: codegen
  return {
    id: entity.order_id_code!,
    createdAt: entity.created_at!,
    pickupCity: entity.pickup_city.value as LocationType,
    deliveryCity: entity.delivery_city.value as LocationType,
    deliveryType: entity.delivery_type.value as DeliveryType,
    containerNumber: entity.container_number ?? undefined,
    containerNumber2: entity.container_number2 ?? undefined,
    containerHeight: entity.container_height?.value ?? undefined,
    containerType: entity.container_type?.value ?? undefined,
    pickupTerminal: entity.pickup_terminal.value ?? undefined,
    deliveryTerminal: entity.delivery_terminal.value ?? undefined,
    warehouseAddress: entity.warehouse_address ?? undefined,
    deliveryDateTimePeriod: entity.delivery_date_time_period ?? undefined,
    statusId: entity.status.value!,
    tariffs: entity.tariff.map((t) => ({ tariff: t.tariff!, type: t.nds_type?.value! as OrderTariffType })),
  };
}

export function toSearchOrdersResponse(
  response: GetActualOrdersListController_AgOrderListResponse,
): SearchOrdersResponse {
  const orders = response.orders ? response.orders.map((entry) => toOrderListEntity(entry)) : [];
  return {
    items: orders,
    pagination: response.pagination ? response.pagination : { offset: 0, limit: 10, total: orders.length },
  };
}

export function fromSearchOrdersRequest(
  request: SearchOrdersRequest,
): GetActualOrdersListController_AgOrderListRequest | GetArchiveOrdersListController_AgOrderListRequest {
  return {
    sort: {
      sorting_field: toKuberEnum(request.sorting.field),
      sorting_order_by: toKuberEnum(request.sorting.direction),
    },
    pagination: request.pagination,
    filter: {
      status: request.filter.status ? toKuberEnum(request.filter.status) : undefined,
      pickup_city: request.filter.pickupCity ? toKuberEnum(request.filter.pickupCity) : undefined,
      delivery_city: request.filter.deliveryCity ? toKuberEnum(request.filter.deliveryCity) : undefined,
      pickup_terminal: request.filter.pickupTerminal ? toKuberEnum(request.filter.pickupTerminal) : undefined,
      delivery_terminal: request.filter.deliveryTerminal ? toKuberEnum(request.filter.deliveryTerminal) : undefined,
      warehouse_address: request.filter.warehouseAddress,
      delivery_type: request.filter.deliveryType ? toKuberEnum(request.filter.deliveryType) : undefined,
      container_type: request.filter.containerType ? toKuberEnum(request.filter.containerType) : undefined,
      cargo_weight_from: request.filter.cargoWeightFrom,
      cargo_weight_to: request.filter.cargoWeightTo,
      payment_type: request.filter.paymentType ? toKuberEnum(request.filter.paymentType) : undefined,
      delivery_date_from: request.filter.deliveryDateFrom,
      delivery_date_to: request.filter.deliveryDateTo,
      // TODO: codegen
      // @ts-ignore
      options: request.filter.options ? request.filter.options.map((o) => toKuberEnum(o)) : undefined,
      delivery_time: request.filter.deliveryTime ? toKuberEnum(request.filter.deliveryTime) : undefined,
    },
  };
}

export function toResultResponse(response: AccountCheckPhoneController_ResultBoolResponse): ResultResponse {
  return {
    result: response.result_bool_entity ? response.result_bool_entity.result : false,
    message: response.result_bool_entity ? response.result_bool_entity.message ?? undefined : undefined,
  };
}

function fromMarkerObjectToMarkerType(data: MarkerEntity): MarkerTypePoint {
  return {
    name: data.name ?? "",
    description: data.description ?? "",
    address: data.address ?? "",
    mapPoint: fromRouteObjectToTuple(data.marker_point),
    markerType: data.marker_type_enum?.value as MarkerName | undefined,
  };
}

function prepareRoute(data: LatLonEntity[] | undefined): RoutePointTuple[] {
  if (!data) return [];

  return data.map((item) => [item.geo_lat ?? 0, item.geo_lon ?? 0]);
}

function fromRouteObjectToTuple(data: LatLonEntity | undefined): RoutePointTuple {
  if (!data) return [0, 0];
  return [data.geo_lat ?? 0, data.geo_lon ?? 0];
}

export function toMapResponse(
  response: GetOrderRouteMapController_AgGetOrderRouteMapResponse,
): MapResponse | undefined {
  if (!response.entity) return;
  return {
    markers: (response.entity.marker_entities ? response.entity.marker_entities : []).map((point) =>
      fromMarkerObjectToMarkerType(point),
    ),
    routeMap: (response.entity.haul_distance ? response.entity.haul_distance : []).map((route) =>
      prepareRoute(route.projected_point),
    ),
  };
}

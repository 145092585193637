import { account } from "@/api/kuber-api";
import {
  AccountInfoEntity,
  ChangePasswordPublicRequest,
  ChangePasswordRequest,
  CompanyInformationEntity,
  CreateCompanyRequest,
  DownloadFileResponse,
  ResetPasswordRequest,
  ResultResponse,
  SearchCompanyRequest,
} from "@/domain";
import { transformError } from "@/shared";
import {
  ChangeUserEmailController_EmailChangeRequest,
  ChangeUserPhoneNumberController_PhoneNumberChangeRequest,
  ConfirmEmailCodeController_EmailVerificationUuidAndCodeRequest,
  EmailAvailableController_EmailAvailableRequest,
  PhoneTokenGetterByPhoneController_AuthSmsPhoneNumberRequest,
  PhoneVerifierByTokenController_PhoneVerificationUuidAndCodeRequest,
  SendCodeForNewEmailController_NewEmailRequest,
  UserInformationEntity,
} from "@/api/kuber-api/codegen";

export function getInfo(): Promise<AccountInfoEntity> {
  return account.api.getAccountInfo().then((response) => account.mappers.toAccountInfoEntity(response));
}

export function resetPassword(request: ResetPasswordRequest): Promise<ResultResponse> {
  return account.api
    .resetPassword(request)
    .then(account.mappers.toResultResponse)
    .catch((error) => {
      throw transformError(error);
    });
}

export function changePasswordPublic(request: ChangePasswordPublicRequest): Promise<ResultResponse> {
  return account.api
    .changePasswordPublic(request)
    .then(account.mappers.toResultResponse)
    .catch((error) => {
      throw transformError(error);
    });
}

export function changePassword(request: ChangePasswordRequest): Promise<ResultResponse> {
  return account.api
    .changePassword(account.mappers.fromChangePasswordRequest(request))
    .then(account.mappers.toResultResponse)
    .catch((error) => {
      throw transformError(error);
    });
}

// export function changeEmail(request: ChangeEmailRequest): Promise<ResultResponse> {
//   return account.api
//     .changeEmail(account.mappers.fromChangeEmailRequest(request))
//     .then((response) => account.mappers.toResultResponse(response))
//     .catch((error) => {
//       throw transformError(error);
//     });
// }

export function changeCurrentEmail(request: ChangeUserEmailController_EmailChangeRequest): Promise<ResultResponse> {
  return account.api
    .changeCurrentEmail(request)
    .then((response) => account.mappers.toResultResponse(response))
    .catch((error) => {
      throw transformError(error);
    });
}

export function confirmCurrentVerifiedEmail(): Promise<string> {
  return account.api
    .confirmCurrentVerifiedEmail()
    .then((response) => response.email_verification_uuid)
    .catch((error) => {
      throw transformError(error);
    });
}

export function verifyConfirmEmailCode(
  request: ConfirmEmailCodeController_EmailVerificationUuidAndCodeRequest,
): Promise<string> {
  return account.api
    .verifyConfirmEmailCode(request)
    .then((response) => response.email_token)
    .catch((error) => {
      throw transformError(error);
    });
}

export function sendConfirmationCode(request: SendCodeForNewEmailController_NewEmailRequest): Promise<string> {
  return account.api
    .sendConfirmationCode(request)
    .then((response) => response.email_verification_uuid)
    .catch((error) => {
      throw transformError(error);
    });
}

export function changeCurrentPhoneNumber(
  request: ChangeUserPhoneNumberController_PhoneNumberChangeRequest,
): Promise<ResultResponse> {
  return account.api
    .changeCurrentPhoneNumber(request)
    .then((response) => account.mappers.toResultResponse(response))
    .catch((error) => {
      throw transformError(error);
    });
}

export function confirmCurrentVerifiedPhoneNumber(): Promise<string> {
  return account.api
    .confirmCurrentVerifiedPhoneNumber()
    .then((response) => response.phone_verification_uuid)
    .catch((error) => {
      throw transformError(error);
    });
}

export function verifyConfirmPhoneNumberCode(
  request: PhoneVerifierByTokenController_PhoneVerificationUuidAndCodeRequest,
): Promise<string> {
  return account.api
    .verifyConfirmPhoneNumberCode(request)
    .then((response) => response.phone_token)
    .catch((error) => {
      throw transformError(error);
    });
}

export function sendConfirmationPhoneNumberCode(
  request: PhoneTokenGetterByPhoneController_AuthSmsPhoneNumberRequest,
): Promise<string> {
  return account.api
    .sendConfirmationPhoneNumberCode(request)
    .then((response) => response.phone_verification_uuid)
    .catch((error) => {
      throw transformError(error);
    });
}

export function confirmEmail(code: string): Promise<ResultResponse> {
  return account.api
    .confirmEmail(code)
    .then(account.mappers.toResultResponse)
    .catch((error) => {
      throw transformError(error);
    });
}

export function sendVerificationEmail(email: string): Promise<ResultResponse> {
  try {
    return account.api.sendVerificationEmail({ email }).then(account.mappers.toResultResponse);
  } catch (error) {
    throw transformError(error);
  }
}

export async function getAtiAuthorizationUrl(): Promise<string> {
  return account.api.getAtiAuthorizationUrl().catch((error) => {
    throw transformError(error);
  });
}

export function searchCompany(request: SearchCompanyRequest): Promise<CompanyInformationEntity[]> {
  return account.api
    .searchCompany(request)
    .then((response) => response.companies.map((e) => account.mappers.toCompanyInformationEntity(e)))
    .catch((error) => {
      throw transformError(error);
    });
}

export function confirmCompany(request: CreateCompanyRequest): Promise<ResultResponse> {
  return account.api
    .confirmCompany(account.mappers.fromCreateCompanyRequest(request))
    .then(account.mappers.toResultResponse)
    .catch((error) => {
      throw transformError(error);
    });
}

export function downloadFile(fileId: string): Promise<DownloadFileResponse> {
  return account.api.downloadFile(fileId);
}

export function checkPhone(phoneNumber: string): Promise<ResultResponse> {
  return account.api
    .checkPhone(phoneNumber)
    .then(account.mappers.toResultResponse)
    .catch((error) => {
      throw transformError(error);
    });
}

export function checkEmailAvailability(
  request: EmailAvailableController_EmailAvailableRequest,
): Promise<ResultResponse> {
  return account.api
    .checkEmailAvailability(request)
    .then(account.mappers.toResultResponse)
    .catch((error) => {
      throw transformError(error);
    });
}

export function getSessions(): Promise<UserInformationEntity[]> {
  return account.api
    .getSessions()
    .then((response) => response.sessions ?? [])
    .then((sessions) => sessions.reverse())
    .catch((error) => {
      throw transformError(error);
    });
}

export function deleteSessions(): Promise<ResultResponse> {
  return account.api
    .deleteSessions()
    .then(account.mappers.toResultResponse)
    .catch((error) => {
      throw transformError(error);
    });
}

import { DeliveryType } from "@/data/constants";
import {
  CalculateTariffRequest,
  CreateOrderRequest,
  LocationType,
  MarkerTypePoint,
  OrderTariffEntity,
  RoutePointTuple,
} from "@/domain";

import { FormState } from "./types";
import { fromValueToISODate } from "@/components/DateTimePicker/utils";
import dayjs from "dayjs";

type BaseOrderRequest = CalculateTariffRequest;

function prepareBaseOrderRequest(form: FormState): Omit<BaseOrderRequest, "deliveryDateFrom" | "deliveryDateTo"> {
  const baseOrderRequest = {
    deliveryType: form.deliveryType!.value as DeliveryType,
    cargos: form.cargos.map((cargo) => ({
      containerHeight: cargo.containerHeight ? cargo.containerHeight.value : undefined,
      containerNumber: cargo.containerNumber ? cargo.containerNumber : undefined,
      cargoName: cargo.name ? cargo.name : undefined,
      cargoWeight: cargo.weight ? +cargo.weight.replace(/\s/g, "") : undefined,
      cargoCost: cargo.cost ? +cargo.cost.replace(/\s/g, "") : undefined,
      zpu: cargo.zpu ?? undefined,
    })),

    containerType: form.containerType?.value!,
    howToCarry: form.howToCarry ? form.howToCarry.value : undefined,

    pickupCity: form.pickupCity?.value! as LocationType,
    pickupTerminal: form.pickupTerminal?.value!,
    deliveryCity: form.deliveryCity?.value! as LocationType,
    deliveryTerminal: form.deliveryTerminal?.value!,
    warehouseAddress: form.warehouseAddress?.value,
    paymentType: form.paymentType,
    options: form.options,
    cargoProperties:
      form.dangerClass || form.temperatureFrom || form.temperatureTo
        ? {
            dangerClass: form.dangerClass ? +form.dangerClass : undefined,
            temperatureFrom: form.temperatureFrom ? +form.temperatureFrom : undefined,
            temperatureTo: form.temperatureTo ? +form.temperatureTo : undefined,
          }
        : undefined,
    promo: form.promo?.value,
  };
  // @ts-ignore: Unreachable code error
  return baseOrderRequest;
}

export function prepareCalculateTariffRequest(form: FormState): CalculateTariffRequest {
  const baseRequest = prepareBaseOrderRequest(form);

  return {
    ...baseRequest,
    warehouseDeliveryDate: form.warehouseDeliveryDate,
  };
}

export function prepareCreateOrderRequest(form: FormState, tariffs: OrderTariffEntity[]): CreateOrderRequest {
  const baseRequest = prepareBaseOrderRequest(form);
  const request: CreateOrderRequest = {
    ...baseRequest,
    tariffs: tariffs,
    additionalInfo: form.additionalInfo,
    shipperInn: form.shipperInn!.value,
    consigneeInn: form.consigneeInn!.value,
    clientInn: form.clientInn?.value,
    warehouseContacts: form.warehouseContacts
      ? form.warehouseContacts.map((formItem) => ({
          name: formItem.name,
          phone: formItem.phone,
        }))
      : [],
    warehouseAddress: form.warehouseAddress?.value,
    warehouseCompanyInn: form.warehouseCompanyInn?.value,
    warehouseDeliveryDate: form.warehouseDeliveryDate,
    // warehouseDeliveryTimePeriod: form.warehouseDeliveryTimePeriod?.value,
    attachments: form.attachments?.map((attachment) => {
      return {
        file: {
          content: attachment.content,
          name: attachment.name,
        },
        type: {
          value: attachment.doc_type,
        },
      };
    }),
    // deliveryDateFrom: form.deliveryDateFrom,
    // deliveryDateTo: form.deliveryDateTo,
    pickupRelease: form.pickupRelease,
    deliveryRelease: form.deliveryRelease,
    pickupStock: form.pickupStock,
    deliveryStock: form.deliveryStock,
    pickupInstruction: form.pickupInstruction,
    deliveryInstruction: form.deliveryInstruction,
  };

  return request;
}

interface DateTimePeriod {
  start: string;
  end: null | string;
}

export const fromComboInputValueToDateTimeFormat = (value: string): DateTimePeriod => {
  const valueArray = value.split(" — ");
  if (valueArray.length > 1) {
    if (valueArray[1].length < 6) {
      return {
        start: dayjs(fromValueToISODate(valueArray[0])).local().format(),
        end: dayjs(fromValueToISODate(valueArray[0].split(" ")[0] + " " + valueArray[1]))
          .local()
          .format(),
      };
    }

    return {
      start: dayjs(fromValueToISODate(valueArray[0])).local().format(),
      end: dayjs(fromValueToISODate(valueArray[1])).local().format(),
    };
  }

  return {
    start: dayjs(fromValueToISODate(value)).local().format(),
    end: null,
  };
};

export const getCentroid = (array: MarkerTypePoint[]): RoutePointTuple => {
  const initialValue = 0;
  const centerX = array.reduce((accumulator, currentValue) => accumulator + currentValue.mapPoint[0], initialValue);
  const centerY = array.reduce((accumulator, currentValue) => accumulator + currentValue.mapPoint[1], initialValue);
  return [centerX / array.length, centerY / array.length];
};

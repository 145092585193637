import {
  BankInfoEntity,
  CompanyInfoEntity,
  ExecutiveAgencyEntity,
  LegalForm,
  ProfileEntity,
  ProfileInfoEntity,
  UpdateBankInfoRequest,
  UpdateCompanyInfoRequest,
  UpdateProfileInfoRequest,
  UploadDocumentRequest,
} from "@/domain";
import { toAddressEntity, toDocumentFileEntity, toWorkflowCheckEntity } from "../shared/mappers";
import {
  CustomerBankBillEntity,
  CustomerCompanyEntity,
  CustomerProfileEntity,
  ExecutiveAgencyEntity as KuberExecutiveAgencyEntity,
  MoverBankBillEntity,
  MoverCompanyEntity,
  MoverProfileEntity,
  NewCustomerBankBillEntity,
  NewCustomerCompanyEntity,
  NewCustomerProfileEntity,
  NewDocumentCustomerEntity,
  NewDocumentMoverEntity,
  NewMoverBankBillEntity,
  NewMoverCompanyEntity,
  NewMoverProfileEntity,
  PrivateCustomerProfileController_MyProfileResponse,
  PrivateMoverProfileController_MyProfileResponse,
} from "../codegen";
import { toKuberEnum } from "../shared";

export function toProfile(
  profile: PrivateCustomerProfileController_MyProfileResponse | PrivateMoverProfileController_MyProfileResponse,
): ProfileEntity {
  return {
    profile: profile.profile_entity ? toProfileEnity(profile.profile_entity) : undefined,
    company: toCompanyEntity(profile.company_entity),
    bank: profile.bank_bill_entity ? toBankInfoEntity(profile.bank_bill_entity) : undefined,
    files: profile.files ? profile.files.map(toDocumentFileEntity) : [],
    workflowChecks: profile.workflow_check_entities ? profile.workflow_check_entities.map(toWorkflowCheckEntity) : [],
    isVerified:
      profile.dir_verification_status_enum.value === "full_verified" ||
      profile.dir_verification_status_enum.value === "partially_verified",
  };
}

export function toProfileEnity(profileInfo: CustomerProfileEntity | MoverProfileEntity): ProfileInfoEntity {
  return {
    firstName: profileInfo?.first_name,
    secondName: profileInfo?.second_name,
    middleName: profileInfo.middle_name ?? null,
    addressPost: profileInfo.address_post ?? null,
    addressRegistration: profileInfo.address_registration ?? null,
    passportSerial: profileInfo.passport_serial ?? null,
    passportNumber: profileInfo.passport_number ?? null,
    passportIssueDate: profileInfo.passport_date_issue ?? null,
    passportIssuedBy: profileInfo.passport_issued_by ?? null,
    passportDepartmentCode: profileInfo.passport_department_code ?? null,
    personalInn: profileInfo.personal_inn ?? null,
    position: profileInfo.position,
    createdAt: profileInfo.created_at,
    prefix: profileInfo.uniq_code,
  };
}

export function toCompanyEntity(companyInfo: CustomerCompanyEntity | MoverCompanyEntity): CompanyInfoEntity {
  return {
    id: companyInfo.company_id!,
    name: companyInfo.name,
    okpo: companyInfo.okpo,
    inn: companyInfo.inn,
    ogrn: companyInfo.ogrn ?? undefined,
    ogrnip: companyInfo.ogrnip ?? undefined,
    kpp: companyInfo.kpp ?? undefined,
    executiveAgency: companyInfo.executive_agency_entity
      ? toExecutiveAgencyEntity(companyInfo.executive_agency_entity)
      : undefined,
    legalForm: companyInfo.dir_profile_type_enum.value! as LegalForm,
    legalForm2: companyInfo.legal_form,
    createdAt: companyInfo.created_at,
    addressRegistration: companyInfo.address_registration_entity
      ? toAddressEntity(companyInfo.address_registration_entity)
      : undefined,
    addressPost: companyInfo.address_post ?? undefined,
    // TODO: codegen
    // @ts-ignore
    nalogType: (companyInfo.dir_nalog_type_enum?.value || companyInfo.nalog_type) ?? undefined,
    securityUniq: companyInfo.security_company_uniq_code,
    sinceFrom: companyInfo.since_from ?? undefined,
  };
}

export function toExecutiveAgencyEntity(executiveAgency: KuberExecutiveAgencyEntity): ExecutiveAgencyEntity {
  return {
    firstName: executiveAgency.first_name,
    middleName: executiveAgency.middle_name ?? undefined,
    secondName: executiveAgency.second_name,
    position: executiveAgency.position,
  };
}

export function toBankInfoEntity(bankInfo: CustomerBankBillEntity | MoverBankBillEntity): BankInfoEntity {
  return {
    bill: bankInfo.bill,
    bic: bankInfo.bic,
    createdAt: bankInfo.created_at,
    bankName: bankInfo.bank_name,
    correspondentAccount: bankInfo.correspondent_account,
    inn: bankInfo.inn,
    kpp: bankInfo.kpp,
    notification: bankInfo.text_notification ?? null,
  };
}

export function toNewCustomerProfileEntity(profileInfo: UpdateProfileInfoRequest["payload"]): NewCustomerProfileEntity {
  return {
    first_name: profileInfo.firstName,
    second_name: profileInfo.secondName,
    middle_name: profileInfo.middleName ?? null,
    passport_serial: profileInfo.passportSerial ?? null,
    passport_number: profileInfo.passportNumber ?? null,
    passport_date_issue: profileInfo.passportIssueDate ?? null,
    passport_issued_by: profileInfo.passportIssuedBy ?? null,
    passport_department_code: profileInfo.passportDepartmentCode ?? null,
    address_registration: profileInfo.addressRegistration ?? null,
    personal_inn: profileInfo.personalInn ?? null,
    position: profileInfo.position,
  };
}

export function toNewMoverProfileEntity(profileInfo: UpdateProfileInfoRequest["payload"]): NewMoverProfileEntity {
  return {
    first_name: profileInfo.firstName,
    second_name: profileInfo.secondName,
    middle_name: profileInfo.middleName ?? null,
    passport_serial: profileInfo.passportSerial ?? null,
    passport_number: profileInfo.passportNumber ?? null,
    passport_date_issue: profileInfo.passportIssueDate ?? null,
    passport_issued_by: profileInfo.passportIssuedBy ?? null,
    passport_department_code: profileInfo.passportDepartmentCode ?? null,
    address_registration: profileInfo.addressRegistration ?? null,
    personal_inn: profileInfo.personalInn ?? null,
    position: profileInfo.position,
  };
}

export function toNewCustomerCompanyEntity(companyInfo: UpdateCompanyInfoRequest["payload"]): NewCustomerCompanyEntity {
  return {
    nalog_type_enum: toKuberEnum(companyInfo.nalogType),
    address_post: companyInfo.addressPost,
    address_registration: companyInfo.addressRegistration,
    executive_agency_entity: {
      first_name: companyInfo.executiveAgency.firstName,
      second_name: companyInfo.executiveAgency.secondName,
      middle_name: companyInfo.executiveAgency.middleName,
      position: companyInfo.executiveAgency.position,
    },
  };
}

export function toNewMoverCompanyEntity(companyInfo: UpdateCompanyInfoRequest["payload"]): NewMoverCompanyEntity {
  return {
    nalog_type_enum: toKuberEnum(companyInfo.nalogType),
    address_post: companyInfo.addressPost,
    address_registration: companyInfo.addressRegistration,
    executive_agency_entity: {
      first_name: companyInfo.executiveAgency.firstName,
      second_name: companyInfo.executiveAgency.secondName,
      middle_name: companyInfo.executiveAgency.middleName,
      position: companyInfo.executiveAgency.position,
    },
    // TODO: codegen
    automatic_act_of_work: true,
  };
}

export function toNewBankBillEntity(
  bankInfo: UpdateBankInfoRequest["payload"],
): NewCustomerBankBillEntity | NewMoverBankBillEntity {
  return {
    bic: bankInfo.bic,
    bill: bankInfo.bill,
  };
}

export function toNewDocument(
  document: UploadDocumentRequest["payload"],
): NewDocumentCustomerEntity | NewDocumentMoverEntity {
  return {
    type: toKuberEnum(document.documentType),
    name: document.attachment.name,
    content: document.attachment.content,
  };
}

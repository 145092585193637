import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { useToggle } from "react-use";

import Modal, { ModalHeader, ModalResult } from "@/components/Modal";
import { AppError } from "@/domain";
import { ErrorModal } from "@/entities/error";
import { config } from "@/shared";

const EMAIL_CONFIRMATION_RESULT_PARAM = "email_confirmed";

const EmailConfirmationModal = () => {
  const router = useRouter();
  const [showModal, toggleModal] = useToggle(false);
  const [confirmationResult, setConfirmationResult] = useState<boolean | null>(null);

  useEffect(() => {
    if (!router.isReady) return;

    const emailConfirmedParam = router.query[EMAIL_CONFIRMATION_RESULT_PARAM] as string | undefined;
    if (!emailConfirmedParam) return;
    const confirmed = emailConfirmedParam === "true";
    if (confirmed) {
      window.postMessage(config.EMAIL_CONFIRMED_EVENT);
      localStorage.setItem(config.EMAIL_CONFIRMED_EVENT, new Date().getTime().toString());
    }
    setConfirmationResult(confirmed);
    toggleModal(true);
  }, [router.isReady]);

  const handleClose = () => {
    const { [EMAIL_CONFIRMATION_RESULT_PARAM]: removedParam, ...other } = router.query;
    router.replace(
      {
        query: { ...other },
      },
      undefined,
      { shallow: true },
    );
    toggleModal();
  };

  const handleReset = () => {
    setConfirmationResult(null);
    handleClose();
  };

  if (confirmationResult === null) return null;

  if (!confirmationResult) {
    return (
      // TODO: change modal to modalComponent
      <ErrorModal
        error={
          new AppError({
            errorMessage:
              "Произошла внутренняя ошибка, попробуйте повторить попытку позже или&nbsp;обратитесь в&nbsp;службу поддержки",
          })
        }
        onClose={handleReset}
      />
    );
  }

  return (
    // TODO: change modal to modalComponent
    <Modal open={showModal} onClose={handleClose} className="w-full at768:w-[420px]">
      <ModalHeader onClose={handleClose}></ModalHeader>
      <ModalResult
        success={true}
        subtitle="Адрес вашей электронной почты успешно&nbsp;подтвержден"
        buttonText="Ок"
        onButtonClick={handleClose}
      />
    </Modal>
  );
};

export default EmailConfirmationModal;

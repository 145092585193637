import { atom } from "jotai";
import type { AdminUserEntity, UserShortInfoEntity } from "@/api/kuber-api/codegen";

interface UserRole {
  value: string;
}

interface UserInfo {
  uuid: string;
  identifier: string;
  role: "customer" | "mover" | "admin";
  prefix: string;
  inn?: string;
  companyName?: string;
}

export interface UserInfoEntity extends UserShortInfoEntity {
  label: string;
  value: string;
}

export interface AdminLogistInfoEntity extends AdminUserEntity {
  label: string;
  value: string;
}

export interface UserDataBase {
  users: UserInfoEntity[];
  customers: UserInfoEntity[];
  movers: UserInfoEntity[];
  admins: AdminLogistInfoEntity[];
  logists: AdminLogistInfoEntity[];
  assistants: AdminLogistInfoEntity[];
  seniors: AdminLogistInfoEntity[];
}

export const userInfoAtom = atom<UserInfo | null>(null);
export const usersAtom = atom<UserDataBase | null>(null);
